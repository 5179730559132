import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextField-root": {
        margin: theme.spacing(1),
      },
      "& .label-root": {
        margin: theme.spacing(1),
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "left",
      color: theme.palette.text.secondary,
    },
    centeredImage: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "1rem",
      width: "150px",
    },
    centeredText: {
      textAlign: "center",
    },
    warningIconStyle: {
      textAlign: "center",
      color: "#FFDC00",
      verticalAlign: "middle",
    },
    box: {
      width: "75%",
    },
    inputLabel: {
      marginLeft: 10,
      marginTop: 3,
    },
    select: {
      width: 250,
      height: 50,
      marginLeft: 0.7,
    },
    gitlabLink: {
      position: "fixed",
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      fontSize: "0.8rem",
      textDecoration: "none",
    },
  })
);
