import React from "react";
import "./App.css";
import SignatureGenerator from "./SignatureGenerator";
import GoogleSignIn from "./GoogleSignIn";

function App() {
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [userProfile, setUserProfile] = React.useState<Profile | null>(null);

  const handleLogin = (loggedIn: boolean, userProfile: Profile | null) => {
    setLoggedIn(loggedIn);
    setUserProfile(userProfile);
  };

  interface Profile {
    id: string;
    email: string;
    name: string;
    imageUrl: string;
  }

  React.useEffect(() => {
    const storedUserProfile = localStorage.getItem("userProfile");

    if (storedUserProfile) {
      const userProfile = JSON.parse(storedUserProfile);
      setLoggedIn(true);
      setUserProfile(userProfile);
    }
  }, []);

  return (
    <>
      {loggedIn ? (
        <SignatureGenerator
          setLoggedIn={setLoggedIn}
          setUserProfile={setUserProfile}
          userProfile={userProfile}
        />
      ) : (
        <div style={{ display: loggedIn ? "none" : "block" }}>
          <GoogleSignIn onLogin={handleLogin} setUserProfile={setUserProfile} />
        </div>
      )}
    </>
  );
}

export default App;
