import React, { useEffect } from "react";
import "./App.css";
import { GoogleWindow, GoogleSignInProps, CredentialResponse } from "./types";
import { LOGO_GRAY_BG } from "./variables";

declare let window: GoogleWindow;

const GoogleSignIn: React.FC<GoogleSignInProps> = ({
  onLogin,
  setUserProfile,
}) => {
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  useEffect(() => {
    const interval = setInterval(() => {
      if (window.google && window.google.accounts) {
        clearInterval(interval);

        window.google.accounts.id.initialize({
          client_id: clientId,
          callback: handleCredentialResponse,
          cancel_on_tap_outside: true,
          context: "signin",
          auto_select: false,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          {
            theme: "outline",
            size: "large",
          }
        );
      }
    }, 100); // Check every 100ms
  }, []);

  function iso88591ToUtf8(str: string) {
    try {
      return decodeURIComponent(escape(str));
    } catch (error) {
      return str; // Return the original string if decoding fails
    }
  }

  const handleCredentialResponse = (response: CredentialResponse) => {
    const payload = JSON.parse(atob(response.credential.split(".")[1]));

    if (payload.hd === "infinitelambda.com") {
      const userProfile = {
        id: payload.sub,
        email: payload.email,
        name: iso88591ToUtf8(payload.name),
        imageUrl: payload.picture,
      };
      onLogin(true, userProfile);
      setUserProfile(userProfile);

      localStorage.setItem("userProfile", JSON.stringify(userProfile));
    } else {
      console.log("Invalid email domain");
    }
  };

  return (
    <div className="google-signin-container" style={{ userSelect: "none" }}>
      <img src={LOGO_GRAY_BG} alt="Logo" style={{ transform: "scale(0.75)" }} />
      <div style={{ padding: "24px 0", fontSize: "16px", userSelect: "none" }}>
        Sign in with your Infinite Lambda Google account to generate your email
        signature.
      </div>
      <div id="google-signin-button"></div>
    </div>
  );
};

export default GoogleSignIn;
