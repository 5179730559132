export const suggestedTitles = [
  "ANALYTICS ENGINEER",
  "ANALYTICS ENGINEERING PRACTITIONER",
  "ANALYTICS ENGINEERING CONSULTANT",
  "ANALYTICS ENGINEERING SENIOR CONSULTANT",
  "ART DIRECTOR",
  "AUDIT LEAD (GLOBAL) AND ACCOUNTING ASSISTANT",
  "BRIDGE FULLSTACK DEVELOPMENT LEAD",
  "BUSINESS ANALYST",
  "BI ENGINEER",
  "CEO",
  "CHAPTER LEAD",
  "CHAPTER LEAD - ANALYTICS ENGINEERING",
  "CHAPTER LEAD - CLOUD NATIVE DEVELOPMENT ENGINEERING",
  "CHAPTER LEAD - DATA ENGINEERING",
  "CHAPTER LEAD - DELIVERY",
  "CHAPTER LEAD - DEVOPS ENGINEERING",
  "CLOUD NATIVE DEVELOPMENT ENGINEER",
  "CLOUD NATIVE DEVELOPMENT ENGINEERING CONSULTANT",
  "CLOUD NATIVE DEVELOPMENT ENGINEERING PRACTITIONER",
  "CLOUD NATIVE DEVELOPMENT ENGINEERING SENIOR CONSULTANT",
  "COMMUNITY BUSINESS PARTNER",
  "CONSULTANT",
  "COO",
  "CTO",
  "DATA ANALYST",
  "DATA ENGINEER",
  "DATA ENGINEERING PRACTITIONER",
  "DATA ENGINEERING CONSULTANT",
  "DATA ENGINEERING SENIOR CONSULTANT",
  "DELIVERY LEAD",
  "DEVOPS ENGINEER",
  "DEVOPS ENGINEERING PRACTITIONER",
  "DEVOPS ENGINEERING CONSULTANT",
  "DEVOPS ENGINEERING SENIOR CONSULTANT",
  "DISTINGUISHED ENGINEER",
  "ENGINEERING LEAD",
  "ENGINEERING LEAD - ANALYTICS ENGINEERING",
  "ENGINEERING LEAD - CND ENGINEERING",
  "ENGINEERING LEAD - DATA ENGINEERING",
  "ENGINEERING LEAD - DEVOPS ENGINEERING",
  "FINANCIAL CONTROLLER",
  "GRAPHICS DESIGNER",
  "HEAD OF PRE-SALES ENGINEERING",
  "HEAD OF TALENT DEVELOPMENT",
  "HR ADMINISTRATION LEAD",
  "HR AND OFFICER ADMINISTRATOR",
  "HR ASSISTANT & OFFICE MANAGER - HUNGARY",
  "HR ASSISTANT & OFFICE MANAGER - SLOVAKIA",
  "HR BUSINESS PARTNER",
  "INNOVATION CONSULTANT",
  "IT/TECH SUPPORT",
  "MANAGING DIRECTOR",
  "MANAGING DIRECTOR - BIONIC UNIT",
  "MANAGING DIRECTOR - HERMES UNIT",
  "MANAGING DIRECTOR ASSISTANT",
  "MARKETING LEAD",
  "MOBILE DEVELOPER",
  "OFFICE COORDINATOR AND DESIGN ASSISTANT",
  "OFFICE MANAGER",
  "PROJECT MANAGER",
  "PRODUCT MANAGER",
  "PRACTITIONER",
  "RECRUITMENT LEAD",
  "SENIOR CONSULTANT",
  "SENIOR ENGINEERING LEAD",
  "SENIOR IT RECRUITER",
  "STAFF ENGINEER",
  "TALENT DEVELOPMENT LEAD",
  "TECH LEAD",
];

export const LOGOS = {
  "il-logo-white":
    "https://email-signature-generator-app.s3.eu-west-1.amazonaws.com/il-logo-white.png",
} as const;

export const LOGO =
  "https://email-signature-generator-public.s3.eu-west-1.amazonaws.com/il-logo-white.png" as const;

export const LOGO_GRAY_BG =
  "https://email-signature-generator-public.s3.eu-west-1.amazonaws.com/il-logo-grayish-bg.png" as const;

export const PARTNERS = {
  "snowflake-partner-logo":
    "https://email-signature-generator-public.s3.eu-west-1.amazonaws.com/snowflake-elite-partner.png",
  "dbt-partner-logo":
    "https://email-signature-generator-public.s3.eu-west-1.amazonaws.com/dbt-platinum-partner.png",
  "fivetran-partner-logo":
    "https://email-signature-generator-public.s3.eu-west-1.amazonaws.com/fivetran-innovation-partner.png",
} as const;

export const PARTNER_URLS = {
  snowflake: "https://infinitelambda.com/snowflake-elite-services-partner/",
  dbt: "https://infinitelambda.com/dbt-labs-platinum-partner/",
  fivetran: "https://infinitelambda.com/fivetran-innovation-partner-award/",
} as const;

export const GITLAB_URL =
  "https://gitlab.infinitelambda.com/infinitelambda/tools/email-signature-generator/-/tree/main/signature-react-app" as const;
