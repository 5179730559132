import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  InputAdornment,
  Link,
  AutocompleteRenderInputParams,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { CheckOutlined, FileCopyOutlined } from "@mui/icons-material";
import React from "react";
import ReactDOMServer from "react-dom/server";
import DownloadIcon from "@mui/icons-material/Download";
import Autocomplete from "@mui/material/Autocomplete";
import Signature from "./Signature";
import { useStyles } from "./styles";
import "./App.css";
import {
  suggestedTitles,
  PARTNER_URLS,
  PARTNERS,
  LOGO,
  LOGOS,
  GITLAB_URL,
} from "./variables";

export interface PhotoSignatureProps {
  logo: keyof typeof LOGOS;
  logoImage: string;
  fullName: string;
  credentials: string;
  title: string;
  email: string;
  url: string;
  motto: string;
  nightMode: boolean;
  fontColorClass: string;
  partner1Image: string;
  partner2Image: string;
  partner3Image: string;
  partner1Url: string;
  partner2Url: string;
  partner3Url: string;
}

interface Profile {
  id: string;
  email: string;
  name: string;
  imageUrl: string;
}

interface SignatureGeneratorProps {
  setLoggedIn: (loggedIn: boolean) => void;
  setUserProfile: (userProfile: Profile | null) => void;
  userProfile: Profile | null;
}

const SignatureGenerator: React.FC<SignatureGeneratorProps> = ({
  setLoggedIn,
  setUserProfile,
  userProfile,
}) => {
  const [emailPrefix, setEmailPrefix] = React.useState("");

  React.useEffect(() => {
    if (userProfile && userProfile.email) {
      const prefix = userProfile.email.split("@")[0];
      setEmailPrefix(prefix);
    }
  }, [userProfile]);

  React.useEffect(() => {
    if (userProfile && userProfile.name) {
      setState((prevState) => ({
        ...prevState,
        fullName: userProfile.name,
      }));
    }
  }, [userProfile]);

  const initialState: State = {
    logo: "il-logo-white",
    logoImage: LOGO,
    fullName: userProfile?.name || "",
    credentials: "",
    title: "",
    email: userProfile?.email || "",
    url: "www.infinitelambda.com",
    motto: "ENGINEERING THAT EMPOWERS PEOPLE",
    copied: false,
    nightMode: false,
    fontColorClass: "font-color-light-mode",
    partner1Image: PARTNERS["snowflake-partner-logo"],
    partner2Image: PARTNERS["dbt-partner-logo"],
    partner3Image: PARTNERS["fivetran-partner-logo"],
    partner1Url: PARTNER_URLS["snowflake"],
    partner2Url: PARTNER_URLS["dbt"],
    partner3Url: PARTNER_URLS["fivetran"],
  };

  const [state, setState] = React.useState<State>(initialState);

  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const downloadHtmlFile = () => {
    const htmlSignature = ReactDOMServer.renderToStaticMarkup(
      <Signature
        logo={state.logo}
        logoImage={state.logoImage}
        fullName={state.fullName}
        credentials={state.credentials}
        title={state.title}
        email={state.email}
        url={state.url}
        motto={state.motto}
        nightMode={state.nightMode}
        fontColorClass={state.fontColorClass}
        partner1Image={state.partner1Image}
        partner2Image={state.partner2Image}
        partner3Image={state.partner3Image}
        partner1Url={state.partner1Url}
        partner2Url={state.partner2Url}
        partner3Url={state.partner3Url}
      />
    );
    const lowerCaseName = state.fullName.toLowerCase();
    const nameSplit = lowerCaseName.split(" ");
    const firstInitial = nameSplit[0].charAt(0);
    const lastName = nameSplit[1];
    const blob = new Blob([htmlSignature]);
    const fileDownloadUrl = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = fileDownloadUrl;
    link.setAttribute("download", `${firstInitial}${lastName}.htm`);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  };

  const hasRequiredFields: boolean =
    !!state.logo && !!state.fullName && !!state.title && !!emailPrefix;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const prefix = event.target.value.replace(/[^a-zA-Z0-9._-]+/g, "");
    setEmailPrefix(prefix);
    setState((prevState) => ({
      ...prevState,
      email: prefix + "@infinitelambda.com",
    }));
  };

  const copyToClipboard = () => {
    const copyText = document.querySelector(".signature");
    const range = document.createRange();
    if (copyText) {
      range.selectNode(copyText);
    }
    const windowSelection = window.getSelection();
    if (windowSelection) {
      windowSelection.removeAllRanges();
      windowSelection.addRange(range);
    }
    try {
      const successful = document.execCommand("copy");
      console.log(successful ? "Success" : "Fail");
      setState((prevState) => ({
        ...prevState,
        copied: true,
      }));
    } catch (err) {
      console.log("Fail");
    }
  };

  const handleLogout = () => {
    window.google.accounts.id.revoke(
      userProfile?.email ?? "",
      (done: boolean) => {
        if (done) {
          setLoggedIn(false);
          setUserProfile(null);
          localStorage.removeItem("userProfile");
        }
      }
    );
  };

  const showSignature = () => {
    return (
      <Signature
        logo={state.logo}
        logoImage={state.logoImage}
        fullName={state.fullName}
        credentials={state.credentials}
        title={state.title}
        email={state.email}
        url={state.url}
        motto={state.motto}
        nightMode={state.nightMode}
        fontColorClass={state.fontColorClass}
        partner1Image={state.partner1Image}
        partner2Image={state.partner2Image}
        partner3Image={state.partner3Image}
        partner1Url={state.partner1Url}
        partner2Url={state.partner2Url}
        partner3Url={state.partner3Url}
      />
    );
  };

  interface State extends PhotoSignatureProps {
    copied: boolean;
  }

  return (
    <Container>
      <div className="google-style-logout" onClick={handleLogout}>
        <img
          className="profile-image"
          src={userProfile?.imageUrl}
          alt="User Avatar"
        />
        <span className="google-style-logout-text">Sign out</span>
      </div>

      <div style={{ margin: "50px" }}>
        <Typography variant="h2" gutterBottom className={classes.centeredText}>
          Signature Generator
        </Typography>
        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.centeredText}
        ></Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5}>
            <div className="form-container">
              <Paper className={classes.paper}>
                <form className={classes.root} noValidate autoComplete="off">
                  <TextField
                    fullWidth={true}
                    required
                    label="Full Name"
                    value={state.fullName}
                    name={"fullName"}
                    onChange={handleChange}
                    autoFocus={true}
                  />
                  <TextField
                    fullWidth={true}
                    label="Credentials / Pronouns"
                    value={state.credentials}
                    name={"credentials"}
                    onChange={handleChange}
                  />
                  <Autocomplete
                    freeSolo
                    options={suggestedTitles}
                    inputValue={state.title}
                    onInputChange={(_, value) =>
                      handleChange({
                        target: { name: "title", value },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(_: any, value: string | null) =>
                      handleChange({
                        target: { name: "title", value: value || "" },
                      } as React.ChangeEvent<HTMLInputElement>)
                    }
                    renderInput={(params: AutocompleteRenderInputParams) => (
                      <TextField
                        {...params}
                        fullWidth
                        required
                        label="Title"
                        margin="normal"
                        variant="outlined"
                      />
                    )}
                  />
                  <TextField
                    fullWidth={true}
                    required
                    label="Email"
                    value={emailPrefix}
                    name={"email"}
                    onChange={handleEmailChange}
                    type="email"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          @infinitelambda.com
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    onClick={copyToClipboard}
                    disabled={!hasRequiredFields}
                    data-testid="copy-link"
                    endIcon={
                      state.copied ? <CheckOutlined /> : <FileCopyOutlined />
                    }
                  >
                    {state.copied ? "Copied" : "Copy to clipboard"}
                  </Button>
                  <Button
                    endIcon={<DownloadIcon />}
                    onClick={downloadHtmlFile}
                    disabled={!hasRequiredFields}
                    data-testid="download-link"
                  >
                    Download HTML File
                  </Button>
                </form>
              </Paper>
            </div>
          </Grid>
          <Grid item>
            <Box className="signature-container">
              <Paper
                className={classes.paper}
                style={{
                  backgroundColor: state.nightMode ? "#333" : "#FFFFFF",
                }}
              >
                {showSignature()}
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Link
        className={classes.gitlabLink}
        href={GITLAB_URL}
        target="_blank"
        rel="noopener noreferrer"
      >
        Contribute on IL GitLab
      </Link>
    </Container>
  );
};

export default SignatureGenerator;
