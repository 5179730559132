import { PhotoSignatureProps } from "./SignatureGenerator";

const Signature = (props: PhotoSignatureProps) => {
  const modeClass = props.nightMode ? "dark-mode" : "light-mode";

  return (
    <div
      className={`signature ${modeClass}`}
      data-testid="signature-box"
      style={{ border: "none" }}
    >
      <table
        border={0}
        cellPadding={0}
        cellSpacing={0}
        className="signature-table"
        style={{
          backgroundColor: modeClass === "light-mode" ? "#FFFFFF" : "#333333",
          border: "none",
          borderCollapse: "collapse",
          borderRadius: "0px",
          paddingLeft: "29px",
        }}
      >
        <tbody>
          <tr>
            <td
              style={{
                color:
                  props.fontColorClass === "font-color-dark-mode"
                    ? "#FFFFFF"
                    : "#333",
                fontFamily: "sans-serif, bold",
                fontWeight: "bold",
                fontSize: "14px",
                border: "none",
              }}
            >
              <span style={{ textTransform: "uppercase" }}>
                {props.fullName}
              </span>
              {props.credentials && `, ${props.credentials}`}
            </td>
          </tr>
          <tr>
            <td
              style={{
                color:
                  props.fontColorClass === "font-color-dark-mode"
                    ? "#FFFFFF"
                    : "#333",
                fontFamily: "sans-serif",
                fontWeight: "normal",
                fontSize: "14px",
                textTransform: "uppercase",
                border: "none",
                paddingBottom: "12px",
              }}
            >
              {props.title}
            </td>
          </tr>
          <tr>
            <td
              style={{
                color:
                  props.fontColorClass === "font-color-dark-mode"
                    ? "#FFFFFF"
                    : "#333",
                fontFamily: "sans-serif",
                fontSize: "14px",
                textTransform: "lowercase",
                border: "none",
              }}
            >
              <a
                href={`mailto:${props.email}`}
                data-testid="email-link"
                style={{
                  textDecoration: "underline",
                  color:
                    props.fontColorClass === "font-color-dark-mode"
                      ? "#FFFFFF"
                      : "#333",
                  fontWeight: "normal",
                }}
              >
                {props.email}
              </a>
            </td>
          </tr>
          <tr>
            <td
              style={{
                color:
                  props.fontColorClass === "font-color-dark-mode"
                    ? "#FFFFFF"
                    : "#333",
                fontFamily: "sans-serif",
                fontSize: "14px",
                textTransform: "lowercase",
                border: "none",
              }}
            >
              <a
                href={`https://${props.url}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "underline",
                  color:
                    props.fontColorClass === "font-color-dark-mode"
                      ? "#FFFFFF"
                      : "#333",
                  fontWeight: "normal",
                }}
              >
                {props.url}
              </a>
            </td>
          </tr>
          <tr>
            <td style={{ border: "none" }}></td>
          </tr>
          <tr>
            <td style={{ paddingTop: "24px", border: "none" }}></td>
          </tr>
          <tr>
            <td
              className={`regular-text ${modeClass} ${props.fontColorClass}`}
              style={{ border: "none" }}
            >
              <table cellPadding={0} cellSpacing={0}>
                <tbody>
                  <tr>
                    <td>
                      <a
                        href={`https://${props.url}`}
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          display: "inline-block",
                          border: "none",
                          paddingBottom: "0",
                        }}
                      >
                        <img
                          src={props.logoImage}
                          alt={"il-logo"}
                          style={{ width: "155px", height: "86px" }}
                        />
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: "0", border: "none" }}></td>
          </tr>
          <tr>
            <td>
              <hr className="horizontal-line"></hr>
            </td>
          </tr>
          <tr>
            <td
              style={{
                color:
                  props.fontColorClass === "font-color-dark-mode"
                    ? "#FFFFFF"
                    : "#333",
                fontFamily: "sans-serif",
                fontSize: "14px",
                border: "none",
                fontWeight: "bold",
              }}
            >
              {props.motto}
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: "10px", border: "none" }}>
              <div style={{ display: "flex" }}>
                <a
                  href={props.partner1Url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "12px" }}
                >
                  <img
                    src={props.partner1Image}
                    alt="partner1"
                    style={{ width: "120px", height: "120px" }}
                  />
                </a>
                <a
                  href={props.partner2Url}
                  target="_blank"
                  rel="noreferrer"
                  style={{ marginRight: "12px" }}
                >
                  <img
                    src={props.partner2Image}
                    alt="partner2"
                    style={{ width: "120px", height: "120px" }}
                  />
                </a>
                <a href={props.partner3Url} target="_blank" rel="noreferrer">
                  <img
                    src={props.partner3Image}
                    alt="partner3"
                    style={{ width: "120px", height: "120px" }}
                  />
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Signature;
